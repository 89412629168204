<template>
  <div class="main">
    <div class="btn">
      <el-button
        type="primary"
        size="small"
        @click="addCategoryType()"
        icon="Plus"
        >新增业务类型</el-button
      >
    </div>
    <el-table :data="categoryList" style="width: 100%">
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column type="expand">
        <template #default="props">
          <div m="4">
            <el-table :data="props.row.children" :show-header="false">
              <el-table-column label="业务名称" prop="categoryName" />
              <el-table-column label="操作">
                <template #default="scope">
                  <el-button
                    type="success"
                    plain
                    @click="editName(scope.row)"
                    size="small"
                    icon="EditPen"
                    >修改</el-button
                  >
                  <el-button
                    type="danger"
                    size="small"
                    @click="delCategoryName(scope.row)"
                    v-show="props.row.children.length > 1"
                    icon="Delete"
                    plain
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="业务类型" prop="categoryType" />
      <el-table-column label="操作">
        <template #default="scope">
          <el-button
            type="primary"
            size="small"
            @click="addCategoryName(scope.row)"
            plain
            icon="Star"
            >新增业务名称</el-button
          >
          <el-button
            type="success"
            plain
            @click="editType(scope.row)"
            size="small"
            icon="Edit"
            >修改</el-button
          >
          <el-button
            type="danger"
            size="small"
            @click="delCategoryType(scope.row)"
            icon="Delete"
            plain
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :close-on-click-modal="false"
      v-model="addCategoryDialog"
      :title="textMap[dialogStatus]"
    >
      <el-form
        :model="categoryForm"
        ref="categoryFormRef"
        :rules="rules"
        status-icon
      >
        <el-form-item
          label="业务类型："
          prop="categoryType"
          v-show="dialogStatus == 'addType'"
        >
          <el-input v-model="categoryForm.categoryType" />
        </el-form-item>
        <el-form-item label="业务名称：" prop="categoryName">
          <el-input v-model="categoryForm.categoryName" />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="addCategoryDialog = false">取消</el-button>
          <el-button
            type="primary"
            @click="
              dialogStatus === 'addType' ? createData() : updateNameData()
            "
          >
            确定
          </el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog
      :close-on-click-modal="false"
      v-model="editTypeDialog"
      title="修改业务类型"
    >
      <el-form :model="form" ref="formRef" :rules="rules" status-icon>
        <el-form-item label="业务类型：">
          <el-input v-model="form.categoryType" />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="editTypeDialog = false">取消</el-button>
          <el-button type="primary" @click="updateType"> 确定 </el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog
      :close-on-click-modal="false"
      v-model="editNameDialog"
      title="修改业务名称"
    >
      <el-form :model="nameForm" ref="nameFormRef" :rules="rules" status-icon>
        <el-form-item label="业务名称：">
          <el-input v-model="nameForm.categoryName" />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="editNameDialog = false">取消</el-button>
          <el-button type="primary" @click="updateName"> 确定 </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  getCategoryList,
  addCategory,
  addCategoryName,
  delCategoryName,
  delCategory,
} from "../../../api/crm";
export default {
  name: "businessType",
  data() {
    return {
      textMap: {
        addName: "新增业务名称",
        addType: "新增业务类型",
      },
      categoryForm: {
        categoryType: "",
        categoryName: "",
        typeId: null,
      },
      categoryList: [],
      addCategoryDialog: false,
      rules: {
        categoryType: [
          {
            required: true,
            message: "请输入业务类型名称",
            trigger: "blur",
          },
        ],
        categoryName: [
          {
            required: true,
            message: "请输入业务名称",
            trigger: "blur",
          },
        ],
      },
      dialogStatus: "",
      form: {},
      editTypeDialog: false,
      editNameDialog: false,
      nameForm: {},
    };
  },

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      getCategoryList({})
        .then((res) => {
          if (res.data.msg == "success") {
            this.categoryList = res.data.data.list;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //新增业务类型
    addCategoryType() {
      this.resetForm();
      this.dialogStatus = "addType";
      this.addCategoryDialog = true;
      this.$nextTick(() => {
        this.$refs["categoryFormRef"].clearValidate();
      });
    },
    //新增业务名称
    addCategoryName(row) {
      this.resetForm();
      this.categoryForm.categoryType = row.categoryType;
      this.categoryForm.typeId = row.id;
      this.dialogStatus = "addName";
      this.addCategoryDialog = true;
      this.$nextTick(() => {
        this.$refs["categoryFormRef"].clearValidate();
      });
    },
    createData() {
      this.$refs["categoryFormRef"].validate((valid) => {
        if (valid) {
          addCategory(this.categoryForm)
            .then((res) => {
              if (res.data.msg == "success") {
                this.$qzfMessage("新建成功");
                this.addCategoryDialog = false;
                this.getList();
                this.$bus.emit("businessTypeUpdate");
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
    updateNameData() {
      this.$refs["categoryFormRef"].validate((valid) => {
        if (valid) {
          addCategoryName(this.categoryForm)
            .then((res) => {
              if (res.data.msg == "success") {
                this.$qzfMessage("新建成功");
                this.addCategoryDialog = false;
                this.getList();
                this.$bus.emit("businessTypeUpdate");
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
    resetForm() {
      this.categoryForm = {
        categoryName: "",
        categoryType: "",
        typeId: null,
      };
    },
    //修改业务类型
    editType(row) {
      this.form = Object.assign({}, row);
      this.editTypeDialog = true;
    },
    updateType() {
      addCategory({
        id: this.form.id,
        categoryType: this.form.categoryType,
      }).then((res) => {
        if (res.data.msg == "success") {
          this.$qzfMessage("修改成功");
          this.$bus.emit("businessTypeUpdate");
          this.$bus.emit("contractUpdate");
          this.$bus.emit("contractUncheck");
          this.editTypeDialog = false;
          this.getList();
        }
      });
    },
    //修改业务名称
    editName(row) {
      this.nameForm = Object.assign({}, row);
      this.editNameDialog = true;
    },
    updateName() {
      addCategoryName({
        id: this.nameForm.id,
        categoryName: this.nameForm.categoryName,
      }).then((res) => {
        if (res.data.msg == "success") {
          this.$qzfMessage("修改成功");
          this.$bus.emit("businessTypeUpdate");
          this.$bus.emit("contractUpdate");
          this.$bus.emit("contractUncheck");
          this.editNameDialog = false;
          this.getList();
        }
      });
    },
    //删除业务名称
    delCategoryName(row) {
      console.log(row);
      this.$confirm("确认删除此业务吗？", "提示", {
        confirmButtonText: "确定",
        cancleButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delCategoryName({
            id: row.id,
          }).then((res) => {
            if (res.data.msg == "success") {
              this.$qzfMessage("成功删除");
              this.getList();
              this.$bus.emit("businessTypeUpdate");
              this.$bus.emit("contractUpdate");
              this.$bus.emit("contractUncheck");
            }
          });
        })
        .catch(() => {});
    },
    //删除业务类型
    delCategoryType(row) {
      this.$confirm("确认删除此业务类型吗？", "提示", {
        confirmButtonText: "确定",
        cancleButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delCategory({
            id: row.id,
          }).then((res) => {
            if (res.data.msg == "success") {
              this.$qzfMessage("成功删除");
              this.getList();
              this.$bus.emit("businessTypeUpdate");
              this.$bus.emit("contractUpdate");
              this.$bus.emit("contractUncheck");
            }
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  padding: 20px;
}
.el-form-item {
  margin-bottom: 22px !important;
}
</style>